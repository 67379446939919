<template>
  <div class="more">
       <!-- 头部 -->
       <van-nav-bar fixed placeholder z-index="100" :title="title" left-text="返回" left-arrow @click-left="onClickLeft"/>
       
      <!-- 会员 -->
         
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getmorelist"
      >
              <div class="vip" v-if="id==2">
                 <div class="substance-item"  @click="jumpvip">
                    <div class="images">
                      <img src="../assets/image/mall-vip.png" alt="" />
                    </div>
                    <div class="details">
                      <div class="details-name">VIP会员</div>
                      <div class="details-suggest">
                        8元/连续包月
                      </div>
                      <div class="details-prices"><span>￥</span>8.0<span class="text"> 起</span></div>
                    </div>
                </div>
              
                <div class="substance-item" @click="jumpvip">
                    <div class="images">
                      <img src="../assets/image/mall-svip.png" alt="" />
                    </div>
                    <div class="details">
                      <div class="details-name">SVIP会员</div>
                      <div class="details-suggest">
                        15元/连续包月
                      </div>
                      <div class="details-prices"><span>￥</span>15.0<span class="text"> 起</span></div>
                    </div>
                </div>
                
                 <div class="substance-item"  @click="jump">
                    <div class="images">
                      <img src="../assets/image/mall-text.png" alt="" />
                    </div>
                    <div class="details">
                      <div class="details-name">字数包</div>
                      <div class="details-suggest">
                        以万字为单位
                      </div>
                      <div class="details-prices"><span>￥</span>0.98<span class="text"> /万字</span></div>
                    </div>
                </div>
            </div>
   
        <div class="content" v-else>

          <ul class="tab">
            <li @click="getmorelist" :class="{active:activeNum == 0}">综合</li>
            <li @click="getsalesVolume" :class="{active:activeNum == 1}">销量</li>
            <li @click="getnewProductDown" :class="{active:activeNum == 2}">
              新品
              <div>
                <div :class="{activesorttop:isSort==true}" class="iconfont icon-jiantou" ></div>
                <div :class="{activesortbottom:isSort==false}" class="iconfont icon-jiantouxia" ></div>
              </div>
            </li>
            <li @click="Collection" :class="{active:activeNum == 3}">收藏</li>
          </ul>

          <div class="box" v-if="list.length !== 0">
            <div  class="box-item"  v-for="item in this.list" :key="item.id" @click="jump(item.id)" >
                      <div class="images">
                        <img :src="`${https}${item.picList[0].picPath}`" alt="" />
                      </div>
                      <div class="details">
                        <div class="details-name">{{ item.productName }}</div>
                        <div class="details-suggest">
                          这是一段简介
                        </div>
                        <div class="details-prices">
                          <span>￥</span>{{item.specialPrices==null?item.productPrices:item.specialPrices}}<span></span>
                          <span class="last" v-show="item.specialPrices">￥{{item.productPrices}}</span>
                        </div>
                        <div class="buy" v-if="item.id=='1'" v-on:click.stop="onStop(item.id)">购买</div>
                      </div>
            </div>
          </div>
          <div v-else class="kong">
             <div class="kong-item">
               <img src="../assets/image/order-default.png" alt="">
               <p>暂无商品</p>
             </div>
          </div>  
    
      </div>
     </van-list>
               <van-divider>热门推荐</van-divider>
        <div class="footer">
            <div class="footer-box" v-for="item in this.recommendList" :key="item.id" @click="jump(item.id)">
                <div class="images">
                    <img :src="`${https}${item.picList[0].picPath}`" alt="" />
                </div>
                <div class="details">
                    <div class="details-name">{{ item.productName }}</div>
                    <div class="details-suggest">
                      这是一段简介
                    </div>
                        <div class="details-prices">
                          <span>￥</span>{{item.specialPrices==null?item.productPrices:item.specialPrices}}<span></span>
                          <span class="last" v-show="item.specialPrices">￥{{item.productPrices}}</span>
                        </div>
                        <div class="buy" v-if="item.id=='1'" v-on:click.stop="onStop(item.id)">购买</div>
                </div>
            </div>
        </div>
     <div style="height:20px"></div>
  </div>
</template>

<script>
import { getMoreList,getCollection,recommendProduct } from "@api/servicemall.js";
import qs from "qs";

  export default {
    data(){
      return{
         id:"",
         title:"",
         loading : false,
         finished : false,
         list:'',
         listUrl:"",
         https:'https://p.writemall.com/mallFile/product/',
         activeNum:'',
         isSort:false,
         recommendList:'',
         recommendurl:'',
      }
    },
    methods:{
       //  返回
      onClickLeft() {
         this.$router.go(-1)
      },
      //跳转vip
      jumpvip(){
        this.$router.push({
          name:'ServiceMallDef'
        })
      },
      //跳转详情
      jump(id){
        let numReg = /^[0-9]*$/
        if(numReg.test(id)){
            this.$router.push({
              name:'details',
              query:{
                id:id
              }
            })
        }else{
          id=16
          this.$router.push({
              name:'details',
              query:{
                id:id
              }
          })
        }
      },
      //获取热门
      async getrecommend(){
        const result = await recommendProduct();
        if(result.code === 0 ){
           this.recommendList = result.data
        }else{
          this.$toast(result.data.msg); 
        }
      },
      //收藏
      async Collection(){
        this.list = []
        this.activeNum = 3
        this.$toast.loading({
            forbidClick: true,
            loadingType: "spinner",
        });
        const data = {
            columnId:JSON.parse(this.id),
            pageNum:1,
            pageSize:10,
        }
        const result = await getCollection(qs.stringify(data));
         if(result.code === 0){

          if(result.data.list.length == 0){
             this.$toast.clear();
             this.loading = false;
             this.finished = true;
          }else{
            this.$toast.clear();
            this.finished = true;
            this.loading = false;
            this.list = result.data.list;
          }
        }else{
          this.$toast(result.data.msg); 
        }
      },
      //销量
      async getsalesVolume(){
         this.activeNum = 1
         this.$toast.loading({
            forbidClick: true,
            loadingType: "spinner",
          });
          const data = {
                columnId:JSON.parse(this.id),
                pageNum:1,
                pageSize:10,
                sales:1,
             }
        const result = await getMoreList(data);
        if(result.code === 0){

          if(result.data.list.length == 0){
             this.$toast.clear();
             this.loading = false;
             this.finished = true;
          }else{
            this.$toast.clear();
            this.finished = true;
            this.loading = false;
            this.list = result.data.list;
          }
        }else{
          this.$toast(result.data.msg); 
        }
      },
       //新品
      async getnewProductDown(){
         this.activeNum = 2
         this.isSort = !this.isSort
         this.$toast.loading({
            forbidClick: true,
            loadingType: "spinner",
          });
        if(this.isSort){
             const data = {
                columnId:JSON.parse(this.id),
                pageNum:1,
                pageSize:10,
                sales:0,
                timeSort:0,
              }
            const result = await getMoreList(data);
            if(result.code === 0){

              if(result.data.list.length == 0){
                this.$toast.clear();
                this.loading = false;
                this.finished = true;
              }else{
                this.$toast.clear();
                this.finished = true;
                this.loading = false;
                this.list = result.data.list;
              }
            }else{
              this.$toast(result.data.msg); 
            }
        }else{
             this.$toast.loading({
                forbidClick: true,
                loadingType: "spinner",
              });
              const data = {
                    columnId:JSON.parse(this.id),
                    pageNum:1,
                    pageSize:10,
                    sales:0,
                    timeSort:1,
              }
            const result = await getMoreList(data);
            if(result.code === 0){

              if(result.data.list.length == 0){
                this.$toast.clear();
                this.loading = false;
                this.finished = true;
              }else{
                this.$toast.clear();
                this.finished = true;
                this.loading = false;
                this.list = result.data.list;
              }
            }else{
              this.$toast(result.data.msg); 
            }
        }
        
      },
      
      //综合
      async getmorelist(){
         this.activeNum = 0
         this.$toast.loading({
            forbidClick: true,
            loadingType: "spinner",
          });
         const data = {
            columnId:JSON.parse(this.id),
            pageNum:1,
            pageSize:10,
            sales:0,
         }
        const result = await getMoreList(data);
        if(result.code === 0){

          if(result.data.list.length == 0){
             this.$toast.clear();
             this.loading = false;
             this.finished = true;
          }else{
            this.$toast.clear();
            this.finished = true;
            this.loading = false;
            this.list = result.data.list;
            console.log(this.list)
            this.list.map(item =>{
              console.log(item)
            })

          }
        }else{
          this.$toast(result.data.msg); 
        }
      },
    },
    created(){
      this.id = this.$route.query.id
      this.title = this.$route.query.title
      this.getmorelist()
      this.getrecommend()
    }
  }
</script>

<style lang="scss" scoped>
.more{
    font-family: PingFang SC;
     ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
    .kong{
    position: relative;
    text-align: center;
    height: 300px;
    .kong-item{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      img{
        width: 162px;
        height: 118px;
      }
      p{
        margin-top: 0.32rem;
        color: #969799;
        font-size: 14px;
        line-height: 0.4rem;
      }
    }
  }
  
  .vip{
     display: flex;
     margin-top: 10px;
     margin-left: 14px;
     margin-right: 15px;
     padding-bottom: 20px;
   .substance-item{
        flex: 1;
        margin-left: 14px;
        margin-top: 14px;
        text-align: center;
        margin-right: 20px;
        .images{
          width: 80px;
          height: 80px;
          background: #fafbfd;
          border-radius: 10px;
          img{
            width: 44px;
            height:46px;
            margin-top: 40px;
            transform: translateY(-23px);
          }
        }
        .details{
            margin-top: 10px;
            text-align: left;
            width: 80px;
            .details-name{
              font-size: 15px;
              font-weight: bold;
              color: #333333;
              overflow:hidden; /*溢出的部分隐藏*/
              white-space: nowrap; /*文本不换行*/
              text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
            }
            .details-suggest{
              font-size: 12px;
              font-weight: 500;
              color: #999999;
              overflow:hidden; /*溢出的部分隐藏*/
              white-space: nowrap; /*文本不换行*/
              text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
              margin-top: 8px;
            }
            .details-prices{
               font-size: 12px;
               margin-top: 8px;
               overflow:hidden; /*溢出的部分隐藏*/
               white-space: nowrap; /*文本不换行*/
               text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
               color: #EB441E;
               .text{
                 color: #CCCCCC;
               }
            }
        }
      }
  }
  .box{
     display: flex;
     flex-wrap:wrap;
     margin-top: 25px;
     margin-left: 14px;
     margin-right: 15px;
     .box-item{
          margin-left: 14px;
          text-align: center;
          width: 78px;
          margin-right: 20px;
           margin-top: 10px;
          .images{
            width: 80px;
            height: 80px;
            background: #fafbfd;
            border-radius: 10px;
            img{
              width: 80px;
              height:80px;
              margin-top: 40px;
              border-radius: 10px;
              transform: translateY(-23px);
            }
          }
          .details{
              margin-top: 30px;
              text-align: left;
              width: 80px;
              .details-name{
                font-size: 15px;
                font-weight: bold;
                color: #333333;
                overflow:hidden; /*溢出的部分隐藏*/
                white-space: nowrap; /*文本不换行*/
                text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
              }
              .details-suggest{
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                overflow:hidden; /*溢出的部分隐藏*/
                white-space: nowrap; /*文本不换行*/
                text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                margin-top: 8px;
              }
              .details-prices{
                font-size: 12px;
                margin-top: 8px;
                overflow:hidden; /*溢出的部分隐藏*/
                white-space: nowrap; /*文本不换行*/
                text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                color: #EB441E;
                    .last{
                      font-family: PingFang SC;
                      font-weight: 500;
                      text-decoration: line-through;
                      color: #CCCCCC;
                      font-size: 12px;
                    }
                .colors{
                  color: #cccccc;
                }
              }
          }
        }
  }
  .content{
     .tab{
       margin-top: 15px;
       font-family: PingFang SC;
       font-weight: 500;
       color: #333333;
       display: flex;
       margin-left: 15px;
       margin-right: 25px;
       li{
         text-align: center;
         font-size: 14px;
         font-family: PingFang SC;
         font-weight: 500;
         >div{
           display: inline-block;
           transform: translateY(1px);
           .icon-jiantou {
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #CCCCCC;
            }
              .icon-jiantouxia {
                margin-top: 3px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #CCCCCC;
              }
              .activesorttop{
                border-bottom: 5px solid #3783F3;
              }
              .activesortbottom{
                 border-top: 5px solid #3783F3;
              }
          }
         
        }
         li:first-of-type{
          margin-right: 70px;
        }
        li:nth-of-type(2){
          margin-right: 70px;
        }
         li:nth-of-type(3){
          margin-right: 64px;
        }
     }
     .active{
       color: #3783F3;
     }
     
    }
  }
  .footer{
       display: flex;
      flex-wrap:wrap;
      margin-left: 14px;
      margin-right: 15px;
     .footer-box{
          margin-left: 14px;
          text-align: center;
          width: 78px;
          margin-right: 20px;
           margin-top: 10px;
          .images{
            width: 80px;
            height: 80px;
            background: #fafbfd;
            border-radius: 10px;
            img{
              width: 80px;
              height:80px;
              margin-top: 40px;
               border-radius: 10px;
              transform: translateY(-23px);
            }
          }
          .details{
              margin-top: 30px;
              text-align: left;
              width: 80px;
              .details-name{
                font-size: 15px;
                font-weight: bold;
                color: #333333;
                overflow:hidden; /*溢出的部分隐藏*/
                white-space: nowrap; /*文本不换行*/
                text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
              }
              .details-suggest{
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                overflow:hidden; /*溢出的部分隐藏*/
                white-space: nowrap; /*文本不换行*/
                text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                margin-top: 8px;
              }
              .details-prices{
                font-size: 12px;
                margin-top: 8px;
                overflow:hidden; /*溢出的部分隐藏*/
                white-space: nowrap; /*文本不换行*/
                text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                color: #EB441E;
                    .last{
                      font-family: PingFang SC;
                      font-weight: 500;
                      text-decoration: line-through;
                      color: #CCCCCC;
                      font-size: 12px;
                    }
                .colors{
                  color: #cccccc;
                }
              }
          }
        }
}
</style>